<template>
  <div v-if="!isMobile">
    <v-tooltip
      v-for="item in environments.filter(x => environmentsFilter.length === 0 || environmentsFilter.indexOf(x.name) >= 0)"
      :key="item.path"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="item.name === 'sys' ? 'red' : 'primary'"
          fab
          v-bind="attrs"
          v-on="on"
          elevation="1"
          small
          style="margin-right:5px"
          @click="handleGoTo(item)"
          :style="{ opacity: environment === item.name ? 1 : 0.3 }"
        >
          <v-icon dark>{{ item.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ item.name }}</span>
    </v-tooltip>
  </div>

  <v-list v-else>
    <v-list-item
      v-for="item in environments.filter(x => environmentsFilter.length === 0 || environmentsFilter.indexOf(x.name) >= 0)"
      :key="item.path"
      @click="handleGoTo(item)"
      :style="{ opacity: environment === item.name ? 1 : 0.3 }"
    >
      <v-list-item-icon>
        <v-icon v-text="item.icon"
          :color="item.name === 'sys' ? 'red' : 'primary'"
        />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title 
          :color="item.name === 'sys' ? 'red' : 'primary'"
        >{{item.name}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import constants from '@/constants'
import api from '@/services/api'
import utils from '@/services/utils'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      environments: [],
    }
  },
  computed: {
    ...mapState('app',['locale', 'environmentsFilter']),
    environment () {
      return this.$route.meta.environment
    },
    isMobile () {
      return utils.isMobile()
    },
  },
  mounted () {
    if (localStorage.getItem('environmentsFilter')) {
      const aux = localStorage.getItem('environmentsFilter').split(',').filter(x => x.length > 0)
      if (aux !== this.$store.state.app.environmentsFilter && this.$store.state.app.environmentsFilter.length === 0) {
        this.$store.state.app.environmentsFilter = aux
      }
    }
    api.getItem ('system', `v1/internal/environments/`, utils.getUser())
      .then(response => {
        this.environments = constants.environments.filter(x => response.Environments.indexOf(x.name) >= 0)
        if (this.environments.length === 0) this.$router.push('/no-env')
        else if (!this.environment) this.handleGoTo(this.environments[0])
      })
    this.prepareColor(this.environment)
  },
  methods: {
    prepareColor (v) {
      if (v === 'status') {
        this.$vuetify.theme.themes.light.primary = '#000000'
      } else if (v === 'status2') {
        this.$vuetify.theme.themes.light.primary = '#000000'
      }
    },
    handleGoTo (v) {
      if (v.path !== `/${this.environment}`) { 
        if (v.name !== 'dtouch') this.$store.state.app.dtouchDeeplTranslateActive = false
        this.prepareColor(v.name)
        this.$store.state.app.environment = v.name
        this.$router.push(v.path)
      }
    }
  }
}
</script>
