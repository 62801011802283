
const booking = {
  name: 'Nombre',
  description: 'Descripción',
  title: 'Título',
  subtitle: 'Subtítulo',
  calendarColor: 'Color evento en vista calendario',
  allowReservations: 'Permitir reservas',
  reservationRequired: 'Requiere reserva',
  moreInfo: '+ info',
  cancelReservation: 'Cancelar r.',
  reservation: 'Reservar',
  addCalendar: '+ CAL',
  place: 'Lugar',
  meetingPoint: 'Punto de encuentro',
  lat: 'Latitud',
  lon: 'Longitud',
  complete: 'Completo',
  available: 'Disponible',
  hideReservationsRequired: 'Ocultar reservas requeridas',
  allowReservation: 'Permitir reservar',
  allowReservationsEventStart: 'Permitir eventos ya empezados',
  forceOrder: 'Forzar orden',
  forceFirst: 'Enviar al inicio de la lista',
  forceLast: 'Enviar al final de la lista',
  imagesFolder: 'Carpeta de imágenes',
  pleaseStandBy: 'Por favor espere',
  details: 'Detalle',
  activity: 'Actividad',
  schedule: 'Programación',
  address: 'Dirección',
  formFieldsDefault: 'Campos de formulario de reserva',
  formFieldsAllowReservationsMessage: 'Para ser usado debe tener activa la opción de permitir reservar.',
  categories: 'Categorías',
  category: 'Categoría',
  filter: 'Filtro',
  date: 'Fecha',
  timetable: 'Horario',
  event: 'Evento',
  excludeHide: 'Excluir ocultos',
  messages: {
    distinctSchedule: 'Diferente programación',
    selectedEventRequired: 'Debe seleccionar un evento.',
    confirmMoveReservation: '¿Está seguro de que desea mover la reserva?',
    showConfirm: '¿Está seguro de que desea mostrar el evento?',
    hideConfirm: '¿Está seguro de que desea ocultar el evento?',
  },
  acttiv: {
    hotel: 'Hotel',
  },
  dviewcontent: {
    title: 'Dviewcontent',
    id: 'Gestor',
  },
  places: {
    title: 'Lugares',
    addPlace: 'Añadir lugar',
    address: 'Dirección',
  },
  customFieldsInfo: {
    title: 'Campos de información personalizado',
  },
  activities: {
    title: 'Actividades',
    addActivity: 'Añadir actividad',
    addActivityAssisted: 'Añadir actividad con asistente',
    editActivity: 'Editar actividad',
    maxRageEventReservationTitle: 'Permitir reservar múltiples días',
    maxRageEventReservation: 'Nº días que se pueden reservar a la vez',
    seatsType: {
      byCapacity: 'por aforo',
      byNumReservation: 'por nº total de reservas',
      byResources: 'por recursos',
    },
    bookingLimit: 'Límite total de reservas',
    seats: 'Plazas',
    seatsActivity: 'Plazas total activitidad',
    seatsByBooking: 'Plazas por reserva',
    timeLimit: 'Límite de tiempo para reserva',
    timeLimitFrom: 'Permite reservar desde',
    timeLimitTo: 'Permite reservar hasta',
    quotes: 'Cupos',
    days: 'días',
    minutes: 'minutos',
    confirmUpdate: '¿Desea actualizar los eventos y programaciones?',
    update: 'Actualizar',
    noUpdate: 'No actualizar',
    steps: {
      titleAndType: 'Título y tipo',
      scheduleType: 'Tipo',
      scheduleTypeSubtitle: 'Tipo de programación de la actividad',
      detail: 'Detalle',
      detailSubtitle: 'Descripción breve de la actividad',
      image: 'Imagen',
      imageSubtitle: 'Imagen de la actividad',
      location: 'Localización',
      locationSubtitle: 'Lugar y punto de encuentro de la actividad',
      reservation: 'Reserva',
      schedule: 'Programación',
      scheduleSubtitle: 'Programación y horario de la actividad.',
      formFields: 'Campos del formulario',
      formFieldsSubtitle: 'Campos solicitados a la hora de reservar la actividad'
    },
  },
  terms: {
    title: 'Termimos y condiciones',
    requiredTermsActive: 'Activar terminos requeridos',
    requiredTerms: 'Terminos requeridos',
  },
  notificationForm: {
    title: 'Notificación',
    notificationExtraInfo: 'Extra info',
  },
  schedules: {
    title: 'Programaciones',
    addSchedule: 'Añadir programación',
    specific: 'Eventos puntuales',
    recurrence: 'Eventos recurrentes'
  },
  scheduleForm: {
    title: 'Programa',
    alias: 'Alias',
    startTime: 'Hora inicio',
    interval: 'Intervalo',
    type: 'Tipo',
    byDay: 'Por fechas',
    byWeek: 'Por semanas',
    byMonth: 'Por mes',
    infoByDay: 'Programación por fechas concretas.',
    infoByWeek: 'Cuando la actividad es recurrente por semanas.<br/> Ej: lunes, jueves y viernes.',
    infoByMonth: 'Cuando la actividad tiene horarios distintos por mes.<br/> Ej: horario de invierno/verano.',
    allDay: 'Todo el día',
    duration: 'Duración',
    isUniqueEventByDay: 'Solo un evento por día.',
    reference: 'Referencia',
    customLimit: 'Personalizar cupos por programación.',
  },
  formFields: {
    ownerData: 'Datos del comprador',
    paxData: 'Datos del asistente',
    owner: 'Comprador',
    pax: 'Asistente',
    paxCheckLabel: 'Contador de asistentes',
    paxCheckInfo: 'Solicita el Nº de asistentes y oculta el formulario de datos del asistente',
    required: 'Requerido',
    addPax: 'Añadir campo del asistente',
    title: 'Campos del formulario',
    name: 'Nombre',
    lastName: 'Apellidos',
    email: 'Email',
    fullName: 'Nombre completo',
    phone: 'Teléfono',
    room: 'Habitación',
    locale: 'Idioma',
    age: 'Edad',
    allergens: 'Alérgenos',
    observations: 'Observaciones',
    add: 'Añadir',
    type: 'Tipo',
    customRoom: ' (solo número y límite de 4)',
  },
  limitFieldsTime: {
    title: 'Límite por campo y tiempo',
  },
  calendar: {
    today: 'Hoy',
    day: 'Día',
    week: 'Semana',
    month: 'Mes',
    fourDays: '4 días',
    available: 'Disponibles',
    reserved: 'Reservadas',
    canceled: 'Canceladas',
    goToReservationList: 'Ir al listado de reservas', 
    editEvent: 'Editar evento',
    deleteEvent: 'Eliminar evento',
    moveEvent: 'Mover evento',
    hideEvent: 'Ocultar evento',
    showEvent: 'Mostrar evento',
  },
  notifications: {
    title: 'Notificaciones',
    sendOwnerMail: 'Enviar email de reserva al cliente',
    sendNotificationOwnerMail: 'Enviar email de notificación al cliente',
  },
  reservations: {
    title: 'Reservas',
    refresh: 'Refrescar',
    numPax: 'Número de asistentes',
    field: 'Campo',
    addReservation: 'Añadir reserva',
    editReservation: 'Editar reserva',
    clientData: 'Datos del cliente',
    paxData: 'Datos de participantes',
    addPax: 'Añadir participante',
    pax: 'Participante',
    pax_: 'Participantes',
    owner: 'Cliente',
    date: 'Fecha',
    time: 'Hora',
    activity: 'Actividad',
    cu: 'Creado por',
    lmu: 'Actualizado por',
    withEvents: 'eventos configurados',
    withBookings: 'con reservas',
    withCompleteBookings: 'con reservas completas',
    withCancelBookings: 'con reservas canceladas',
    required: 'Requerido',
    key: 'Identificador interno',
    label: 'Etiqueta',
    type: 'Tipo',
    activities: 'Actividades',
    events: 'Eventos',
    workspace: 'Gestor de reserva',
    min: 'Min',
    max: 'Max',
    adultsPax: 'Adultos',
    childrensPax: 'Niños',
    babiesPax: 'Bebés',
    cancelMultiReservation: 'Cancelar multi.',
    resource: 'Recurso',
    rate: 'Tarifa',
    categories: 'Categorías',
    moveReservation: 'Mover reserva',
    activityColor: {
      typeColor: 'Tipo',
      weekPrimaryColor: 'Semana primario',
      weekSecondaryColor: 'Semana secundario',
      contentColor: 'Color contenido',
      titleColor: 'Color título',
      timeColor: 'Color hora',
    },
    myBookings: {
      expandColor: 'Menú',
      btnColor: 'Botón',
      bgColor: 'Fondo',
      ticketBGColor: 'Fondo ticket',
      ticketTextColor: 'Texto ticket',
    },
    formFieldsTypes: {
      title: 'Tipo',
      text: 'Texto',
      birthday: 'Fecha nacimiento',
      boolean: 'SI/NO',
    },
    status: {
      title: 'Estado',
      default: 'Reservado',
      canceled: 'Cancelado',
    },
    messages: {
      fieldRequired: 'Requerido',
      fieldNotValid: 'No válido',
      noData: 'No existen reservas para la fecha seleccionada',
      noEventsForSelectedDate: 'No existen eventos para la fecha seleccionada',
      statusCanceledNoEditable: 'Las reservas canceladas no se pueden editar',
      resourceNotAvailable: 'Recurso no disponible',
    },
  },
  relocateReservations: {
    title: 'Recolocación de reservas',
  },
  attendance: {
    title: 'Registro de entrada',
    titleNoBooking: 'Registro de entrada (sin reservas)',
    type: 'Tipo',
    id: 'ID',
    sign: 'Firmar',
  },
  resources: {
    title: 'Recursos',
    name: 'Nombre',
    label: 'Nombre campo en form. reserva',
    addResource: 'Añadir recurso',
    editMap: 'Editar mapa de asientos',
    seatMapImage: 'Icono mapa de asientos',
    isSeatMap: 'Tiene mapa de asientos',
    limitAllDay: 'El recurso se bloquea para todo el día'
  },
}
export default booking
